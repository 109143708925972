import "./style.scss";
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import { debounce } from "lodash";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import { CustomEase } from "gsap/CustomEase";

gsap.registerPlugin(ScrollTrigger, SplitText, CustomEase);
gsap.config({ nullTargetWarn: false });

var imagesLoaded = require("imagesloaded");

// import barba from "@barba/core";
// import { pageEnter, pageLeave } from './animations'

// import Cookies from 'js-cookie'

import Glide from "@glidejs/glide";

import Cookies from "js-cookie";

let logoSliderLoaded = false;

setDocHeight();
function setDocHeight() {
	document.documentElement.style.setProperty(
		"--vh",
		`${window.innerHeight / 100}px`
	);
}

let loadHeader = gsap
	.timeline({ paused: true })
	.fromTo(
		"header .text-logo path",
		{
			autoAlpha: 0,
			x: -30,
		},
		{
			autoAlpha: 1,
			x: 0,
			stagger: 0.1,
		}
	)
	.set(
		"#menu-header",
		{
			autoAlpha: 1,
		},
		"-=.25"
	)
	.fromTo(
		"#menu-header li",
		{
			autoAlpha: 0,
		},
		{
			autoAlpha: 1,
			stagger: 0.1,
		},
		"-=.25"
	)
	.fromTo(
		"header .enquire",
		{
			autoAlpha: 0,
		},
		{
			autoAlpha: 1,
		},
		"-=.5"
	);

// hero_height();

function logoSliders() {
	let sliders = document.querySelectorAll(".logos-slider");
	sliders.forEach((element) => {
		new Glide(element, {
			autoplay: 6000,
			perView: 4,
			gap: 100,
			peek: 200,
			type: "carousel",
			animationDuration: 2000,
			animationTimingFunc: "ease-in-out",
			breakpoints: {
				1024: {
					perView: 3,
				},
				992: {
					perView: 2,
				},
				768: {
					perView: 2,
					gap: 20,
					peek: 40,
				},
			},
		}).mount();

		gsap.to(
			$(element).find(".glide__slide"),

			{
				autoAlpha: 1,
				stagger: 0.2,
				// delay: 1,
				scrollTrigger: {
					trigger: element,
					// markers: true,
				},
			}
		);
	});
}

function hero_height() {
	if ($(window).innerWidth() > 1024) {
		$("#hero").css(
			"height",
			$(window).height() -
				$("#logo-slider").innerHeight() -
				$("header").height()
		);
	} else {
		$("#hero").css("height", "");
	}
	$("#hero").css("margin-top", $("header").height());

	start();
}
window.addEventListener("resize", _.debounce(hero_height, 250));
// $("#hero")
// 	.find("video")
// 	.each(function(i, video) {
// 		// video.play();
// 		$(video).on("loadeddata", hero_height);
// 	});

let win = $(window);
if ($(".home").length) {
	zoom();
	imgvid_section();
}

if ($(".loader").length) {
	if (!Cookies.get("loaded")) {
		gsap.timeline()
			.to(".loader svg", { autoAlpha: 1, duration: 0.1 })
			.to(".loader svg ellipse", { autoAlpha: 1, duration: 1 })
			.to(".loader svg ellipse", { autoAlpha: 0, duration: 1 })
			.to(".loader svg ellipse", {
				autoAlpha: 1,
				onComplete: () => {
					Cookies.set("loaded", true);

					if ($(".home").length) {
						if ($("#hero").find("video").length) {
							$("#hero")
								.find("video")
								.each(function(i, video) {
									logoSliders();
									hero_height();
									gsap.to(".loader", {
										autoAlpha: 0,
										onComplete: () => {
											Cookies.set("loaded", true);
										},
									});
								});
						} else {
							logoSliders();
							hero_height();
							gsap.to(".loader", {
								autoAlpha: 0,
								onComplete: () => {
									Cookies.set("loaded", true);
									start();
								},
							});
						}
					} else {
						gsap.to(".loader", {
							autoAlpha: 0,
							onComplete: () => {
								Cookies.set("loaded", true);
								start();
							},
						});
					}
				},
			});
	} else {
		if ($(".home").length) {
			if ($("#hero").find("video").length) {
				$("#hero")
					.find("video")
					.each(function(i, video) {
						// video.play();
						$(video).on("loadeddata", function() {
							logoSliders();
							hero_height();

							gsap.to(".loader", {
								autoAlpha: 0,
								onComplete: () => {
									Cookies.set("loaded", true);
									start();
								},
							});
						});
					});
			} else {
				logoSliders();
				hero_height();

				gsap.to(".loader", {
					autoAlpha: 0,
					onComplete: () => {
						Cookies.set("loaded", true);
						start();
					},
				});
			}
		} else {
			gsap.to(".loader", {
				autoAlpha: 0,
				onComplete: () => {
					Cookies.set("loaded", true);
					start();
				},
			});
		}
	}
}

function start() {
	loadHeader.play();

	$(".split-lines").each(function(index) {
		let tl = tl + index;

		tl = gsap.timeline({
			paused: true,
			scrollTrigger: {
				trigger: $(this),
				start: "top 85%",
				// markers: true,
			},
		});

		tl.set($(this), { autoAlpha: 1 }).to($(this).find("div"), {
			autoAlpha: 1,
			y: 0,
			stagger: 0.1,
		});
	});

	ScrollTrigger.batch(".fade-in", {
		start: "top 85%",
		interval: 0.1,
		// markers: true,
		// onEnter: (elements, triggers) => {
		onEnter: (batch) =>
			gsap.to(batch, {
				autoAlpha: 1,
				y: 0,
				stagger: {
					each: 0.15,
					// grid: [1, 3],
				},
				overwrite: true,
			}),
	});

	$(document)
		.find(".button-main")
		.each(function() {
			gsap.timeline({
				defaults: { duration: 0.2 },
				scrollTrigger: {
					trigger: $(this),
					start: "top 80%",
				},
			})
				.to($(this).find(".button-top"), { x: 0 })
				.to($(this).find(".button-right"), { y: 0 })
				.to($(this).find(".button-bottom"), { x: 0 })
				.to($(this).find(".button-left"), {
					y: 0,
					onComplete: () => {
						if ($(this).hasClass("button-b")) {
							$(this).addClass("button-black");
						} else if ($(this).hasClass("button-w")) {
							$(this).addClass("button-white");
						}
					},
				})
				.to($(this).find("div"), { autoAlpha: 1, duration: 0.25 }, 0);
		});

	// setTimeout(() => {
	// 	gsap.set(".flickbook", {
	// 		opacity: 0,
	// 		scrollTrigger: {
	// 			start: "top bottom",
	// 			trigger: ".rows-section",
	// 			markers: true,
	// 		},
	// 	});
	// }, 1000);
}

var split = new SplitText(".split-words", { type: "words" });
var splith1 = new SplitText(".split-lines", {
	type: "lines",
	linesClass: "transform translate-y-full opacity-0",
});

let open_menu = gsap
	.timeline({
		paused: true,
	})
	.fromTo(
		".mobile-menu",
		{
			autoAlpha: 0,
		},
		{
			autoAlpha: 1,
		}
	)
	.fromTo(
		".mobile-menu li",
		{
			autoAlpha: 0,
			y: 10,
		},
		{
			autoAlpha: 1,
			y: 0,
			stagger: 0.1,
		}
	)
	.fromTo(
		".mobile-menu a.button",
		{
			autoAlpha: 0,
		},
		{
			autoAlpha: 1,
			duration: 0.25,
		},
		"-=.25"
	);

$("#mob-menu").on("click", function() {
	if (open_menu.progress() == 0) {
		open_menu.play();
		$("#mob-menu").addClass("active");
	} else {
		open_menu.reverse();
		$("#mob-menu").removeClass("active");
	}
});

let lastWidth;
window.addEventListener(
	"resize",
	_.debounce(function() {
		if ($(window).width() != lastWidth) {
			setDocHeight();
			lastWidth = $(window).width();
		}
	}, 250)
);
window.addEventListener("orientationchange", _.debounce(setDocHeight, 250));

$(".button, ._submit").wrapInner(function() {
	return "<div class='z-10 relative flex items-center justify-between w-full'></div>";
});

$("._submit").addClass(["button-main", "button-black"]);

$(document)
	.find(".button-main")
	.append(
		'<span class="button-top"></span><span class="button-right"></span><span class="button-left"></span><span class="button-bottom"></span>'
	);

//accas

$(".acc-header").on("click", function() {
	$(this).toggleClass("active");
	$(this)
		.next()
		.slideToggle();
	$(this)
		.find(".caption div")
		.toggle();
});

// Header intro

// Overlays

let formOverlay = gsap.to("#form-overlay", {
	autoAlpha: 1,
	paused: true,
	duration: 0.2,
});

$(".open-form-overlay").on("click", function(e) {
	e.preventDefault();
	formOverlay.play();
});

$("#close-form-overlay").on("click", function(e) {
	e.preventDefault();
	formOverlay.reverse();
});

let signUpOverlay = gsap.to("#signUp-overlay", {
	autoAlpha: 1,
	paused: true,
	duration: 0.2,
});

$(".open-signUp-overlay").on("click", function(e) {
	e.preventDefault();
	signUpOverlay.play();
});

$("#close-signUp-overlay").on("click", function(e) {
	e.preventDefault();
	signUpOverlay.reverse();
});

let iframeOverlay = gsap.to("#iframe-overlay", {
	autoAlpha: 1,
	paused: true,
	duration: 0.2,
});

$(".open-iframe-overlay").on("click", function(e) {
	e.preventDefault();
	iframeOverlay.play();
});

$("#close-iframe-overlay").on("click", function(e) {
	e.preventDefault();
	iframeOverlay.reverse();
});

// Testimonials slider

if ($("#testimonials-slider").length) {
	new Glide("#testimonials-slider", {
		type: "slider",
		autoplay: 8000,
	}).mount();
}

function featured_blog() {
	let scrolltrig = ScrollTrigger.create({
		trigger: ".posts-grid",
		start: "top=-45px" + $("header").height(),
		// endTrigger: ".posts-grid",
		end: "bottom bottom",
		pin: ".posts-grid .featured-col a",
		pinSpacing: false,
		// markers: true,
	});

	if ($(window).width() > 1024) {
		scrolltrig.enable();

		$(".posts-grid .featured-col a .post-image img").css(
			"height",
			$(window).height() -
				$(".posts-grid .featured-col a .post-content").height() -
				$("header").height() -
				70
		);
	} else {
		scrolltrig.disable();

		$(".posts-grid .featured-col a img").css("height", "");
	}
}
featured_blog();
$(window).on("resize", featured_blog);

// Logo sliders

// Gallery section

// imagesLoaded( '#gallery-images', function() {
// 	// images have loaded
// 	gallery_image_size()
// });

// $(window).on('resize', _.debounce(function(){

// 	gallery_image_size()

// }, 250))

// function gallery_image_size(){

// 	var maxHeight = 0;

// 	$("#gallery-images .gallery-image img").each(function(){
// 	    if ($(this).height() > maxHeight) {
// 		   maxHeight = $(this).height();
// 		}
// 	});

// 	// $("#gallery-images .gallery-image").height(maxHeight);

// }

// let imgs = $("#floating-images .floating-image");
// let no_of_imgs = imgs.length;
// imgs.each(function(index) {
// 	let imgh = $(this).innerHeight();
// 	let winh = $("#floating-images").height();
// 	let duration = 20;
// 	let ind = "introAnim" + index;
// 	let rep = "repeatAnim" + index;

// 	// let delay = duration / (no_of_imgs) * (index + 0)
// 	let delay = 8;
// 	let stagger = index * delay;

// 	ind = gsap.timeline().to(
// 		$(this),
// 		{
// 			y: (winh + imgh) * -1,
// 			duration: duration,
// 			delay: stagger,
// 			ease: CustomEase.create(
// 				"custom",
// 				"M0,0,C0.124,0.496,0.071,0.387,0.15,0.442,0.234,0.5,0.646,0.768,1,1"
// 			),
// 			// ease: 'linear',
// 			onStart: () => {
// 				gsap.to($(this).find(".inset-0"), {
// 					yPercent: -101,
// 					delay: duration / 9,
// 				});
// 			},
// 			onComplete: () => {
// 				rep.play();
// 			},
// 		},
// 		0
// 	);

// 	rep = gsap
// 		.timeline({ paused: true, repeat: -1 })
// 		.set($(this), { y: 0 })
// 		.to($(this), {
// 			y: (winh + imgh) * -1,
// 			duration: duration,
// 			delay: (no_of_imgs - 1) * delay + delay - duration,
// 			ease: "linear",
// 		});

// 	gsap.from($(this).find("video"), {
// 		scrollTrigger: {
// 			trigger: "#floating-images",
// 			start: "top 25%",
// 			end: "bottom 85%",
// 			scrub: 1,
// 			// markers: true
// 		},
// 		scale: 1.3,
// 	});
// });

// imagesLoaded("#gallery-images", function() {
// 	gsap.to($("#gallery-images"), {
// 		xPercent: -100,
// 		x: () => innerWidth,
// 		ease: "linear",
// 		scrollTrigger: {
// 			trigger: "#gallery-pin",
// 			start: "top top",
// 			end: "bottom top",
// 			pin: true,
// 			pinSpacing: true,
// 			scrub: true,
// 			invalidateOnRefresh: true,
// 			// markers: true,
// 		},
// 	});

// 	gsap.to($(".fadethisout"), {
// 		autoAlpha: 0,
// 		ease: "linear",
// 		scrollTrigger: {
// 			trigger: ".fadethisout",
// 			start: "top +=30",
// 			end: "bottom top",
// 			scrub: true,
// 			// markers: true,
// 		},
// 	});

// 	// Tabs section

// 	let end = $("header").height() + $(window).innerHeight() * 2;

// 	gsap.to("#section-tabs", {
// 		scrollTrigger: {
// 			trigger: "#tabs-header",
// 			pin: true,
// 			pinSpacing: false,
// 			start: "top " + $("header").height(),
// 			end: "+=" + end,
// 			// onToggle: self => $('#tabs-header').toggleClass('z-10'),
// 		},
// 	});

// 	function tabs_size() {
// 		$("#tabs-content").css("width", $("#section-tabs").width() * 3);
// 	}
// 	tabs_size();
// 	$(window).on(
// 		"resize",
// 		_.debounce(
// 			function() {
// 				tabs_size();

// 				let tab = $("#tabs-header .active").attr("data-tab");

// 				gsap.to("#tabs-content", {
// 					x: $("#section-tabs").width() * tab * -1,
// 					ease: "power4.Out",
// 					duration: 0.75,
// 				});
// 			},
// 			250,
// 			{
// 				leading: true,
// 			}
// 		)
// 	);

// 	$("#tabs-header").on("click", "button", function() {
// 		let tab = $(this).attr("data-tab");

// 		$("#tabs-header button").removeClass("active");
// 		$(this).addClass("active");

// 		gsap.to("#tabs-content", {
// 			x: $("#section-tabs").width() * tab * -1,
// 			ease: "power4.Out",
// 			duration: 0.75,
// 		});
// 	});

// 	$("#tabs-header button").each(function() {
// 		$(this).html(function() {
// 			var text = $(this)
// 				.text()
// 				.split(" ");
// 			var last = text.pop();
// 			var first = text.shift();
// 			var second = text.shift();
// 			return (
// 				text.join(" ") +
// 				'<span class="hidden md:inline-block">' +
// 				first +
// 				" " +
// 				second +
// 				'</span> <span class="md:font-normal italic capitalize md:normal-case">' +
// 				last +
// 				"</span>"
// 			);
// 		});
// 	});
// });

// Heading load

// $(".fade-in").each(function(index) {

// });

gsap.timeline({
	paused: true,
	scrollTrigger: {
		trigger: "footer .text-logo",
		start: "top 85%",
		// markers: true
	},
}).fromTo(
	"footer .text-logo path",
	{
		autoAlpha: 0,
		x: -30,
	},
	{
		autoAlpha: 1,
		x: 0,
		stagger: 0.1,
	}
);

// $('#iframe-section').on('mouseenter', function(){

// })

// var $circle = $("#hover-cursor"),
// 	$wrapper = $("#iframe-section");

// if ($circle.length) {
// 	$circle.css({ top: $wrapper.position().top });
// }

// function moveCircle(e) {
// 	gsap.to($circle, {
// 		css: {
// 			left: e.pageX,
// 			top: e.pageY,
// 		},
// 	});
// }

// var flag = false;
// $($wrapper).on("mouseover", function() {
// 	flag = true;
// 	gsap.to($circle, { scale: 1, autoAlpha: 1 });
// 	$($wrapper).on("mousemove", moveCircle);
// });

// $($wrapper).on("mouseout", function() {
// 	flag = false;
// 	gsap.to($circle, { scale: 0.1, autoAlpha: 0 });
// });
// //
// Custom services images on pricing page
//
// wait for images to load
$(".image-height-wrapper img").imagesLoaded(setCustomServicesHeight);

function setCustomServicesHeight() {
	if ($(window).width() >= 768) {
		// add heights of images to array
		let myArray = $(".image-height-wrapper img").map(function() {
			return Math.floor($(this).height());
		});
		//get max value from array
		var maxValueInArray = Math.max(...myArray);
		// set all images's wrappers to max height
		$(".image-height-wrapper").css("height", maxValueInArray);
	} else {
		$(".image-height-wrapper").css("height", "");
	}
}

$(".tab-header button").on("click", function() {
	let tabNo = $(this).attr("data-tab-heading");

	$(".tab-header").removeClass("active");
	$(this)
		.parent()
		.addClass("active");

	$(".tab-group").removeClass("active");
	$('.tab-group[data-tab="' + tabNo + '"]').addClass("active");
});
$(".plan-toggle button").on("click", function() {
	$(this).addClass("active");
	$(this)
		.siblings()
		.removeClass("active");

	$(".tab-group.active")
		.find(".price p")
		.toggleClass("active");

	$(".tab-group.active")
		.find(".buttons .time > div")
		.toggleClass("active");
});

$(".currency-toggle button").on("click", function() {
	$(this).addClass("active");
	$(this)
		.siblings()
		.removeClass("active");

	$(".tab-group.active")
		.find(".currency > span")
		.toggleClass("active");

	$(".tab-group.active")
		.find(".buttons > div")
		.toggleClass("active");
});

$(".see-more").on("click", function() {
	$(this)
		.closest(".tab-group")
		.find(".plan-item")
		.removeClass("hidden");

	$(this)
		.closest(".tab-group")
		.find(".see-more")
		.parent()
		.remove();
});

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const tab = urlParams.get("tab");
if (urlParams.has("tab")) {
	$('.tab-headings button[data-tab-heading="' + tab + '"]').trigger("click");
}

$(window).on("resize", _.debounce(setCustomServicesHeight, 200));

function imgvid_section() {
	// console.log(baseurl);

	const html = document.documentElement;
	const canvas = document.getElementById("hero-lightpass");
	const context = canvas.getContext("2d");

	const frameCount = 250;
	const currentFrame = (index) =>
		`/imgvid/VR_Headset_v02_${index
			.toString()
			.replace("-", "")
			.padStart(3, "0")}.jpg`;

	const preloadImages = () => {
		for (let i = 1; i < frameCount; i++) {
			const img = new Image();
			img.src = currentFrame(i);
			console.log("preload complate");
		}
	};

	preloadImages();

	var img = new Image();
	img.src = currentFrame(1);

	canvas.width = win.width();
	canvas.height = win.height() + 300;

	let imgWidth = 1280;
	let imgHeight = 720;

	img.onload = function() {
		var scale = Math.max(
			canvas.width / imgWidth,
			canvas.height / imgHeight
		);
		var x = canvas.width / 2 - (imgWidth / 2) * scale;
		var y = canvas.height / 2 - (imgHeight / 2) * scale;
		context.drawImage(img, x, y, imgWidth * scale, imgHeight * scale);
	};

	const updateImage = (index) => {
		img.src = currentFrame(index);
		var scale = Math.max(
			canvas.width / imgWidth,
			canvas.height / imgHeight
		);
		var x = canvas.width / 2 - (imgWidth / 2) * scale;
		var y = canvas.height / 2 - (imgHeight / 2) * scale;
		context.drawImage(img, x, y, imgWidth * scale, imgHeight * scale);
	};

	// window.addEventListener('scroll', () => {
	win.on("scroll", function() {
		// canvas.height = win.height();

		let section = document.querySelector(".svg-section");

		const scrollTop = html.scrollTop - $(window).height() - 400;
		const maxScrollTop = section.scrollHeight - window.innerHeight - 800;

		// console.log(maxScrollTop);

		let imgvid_pos = section.scrollHeight;
		let imgvid_end = section.scrollHeight + $(section).height();

		if (
			scrollTop + imgvid_pos > imgvid_pos &&
			scrollTop + imgvid_pos <= imgvid_end
		) {
			const scrollFraction = scrollTop / maxScrollTop;
			const frameIndex = Math.min(
				frameCount - 1,
				Math.ceil(scrollFraction * frameCount)
			);

			if (frameIndex >= frameCount - 1) {
				gsap.to(".flickbook", { autoAlpha: 0 });
			} else {
				gsap.to(".flickbook", { autoAlpha: 1 });
			}

			requestAnimationFrame(() => updateImage(frameIndex + 1));
		}
	});
}

// gsap.set(".flickbook", {
// 	opacity: 1,
// 	scrollTrigger: {
// 		start: "top bottom",
// 		trigger: ".flickbook",
// 		markers: true,
// 	},
// });

let progressTween;

function zoom() {
	let svgzoom = gsap
		.timeline({ paused: true })
		.addLabel("the-start")
		// .to('.svg-section .zoomv-text', { autoAlpha: 1, ease: "power4.in" }, 'the-start')
		// .to('.svg-section .zoomv-wipe', { autoAlpha: 0, ease: "power4.in" }, 'the-start')
		.to(
			".svg-section .svg-window",
			{ scale: 15, ease: "power4.in" },
			"the-start"
		)
		.to(".svg-section .svg-window", {
			autoAlpha: 0,
			ease: "none",
			duration: 0.2,
		})
		.set(".svg-section .svg-window", { display: "none" });

	function handler_zoom(entries, observer) {
		entries.forEach((entry) => {
			if (entry.intersectionRatio > 0) {
				gsap.ticker.add(progressTween);
				// $('.zoomv-video video').play()
			} else {
				gsap.ticker.remove(progressTween);
			}
		});
	}

	const observer_zoom = new IntersectionObserver(handler_zoom, {
		threshold: 0.5,
	});

	progressTween = () => {
		// Get scroll distance to bottom of viewport.
		const scrollPosition = win.scrollTop();
		// Get element's position relative to bottom of viewport.
		const elPosition = scrollPosition - $(".svg-section").position().top;
		// Set desired duration.
		const durationDistance = win.height() * 0.75;
		// Calculate tween progresss.
		const currentProgress = elPosition / durationDistance;
		// Set progress of gsap timeline.
		svgzoom.progress(currentProgress);
	};

	observer_zoom.observe(document.querySelector(".svg-section"));

	if (win.scrollTop() > $(".svg-section").position().top + win.height()) {
		svgzoom.progress(1);
	}
}

if ($("video").length) {
	videos();
}
function videos() {
	let videos = document.querySelectorAll("video");

	function handler(entries, observer) {
		entries.forEach((entry) => {
			if (entry.isIntersecting == true) {
				entry.target.play();
			} else {
				entry.target.pause();
			}
		});
	}

	const videoobserver = new IntersectionObserver(handler, {
		threshold: 0,
	});

	videos.forEach((item) => {
		videoobserver.observe(item);
	});
}

win.on(
	"resize",
	_.debounce(() => {
		if ($("body").hasClass("home")) {
			imgvid_section();
		}
	}, 250)
);

if ($("body").hasClass("single-post")) {
	ScrollTrigger.create({
		trigger: "header",
		start: "bottom top",
		onEnter: (self) => {
			gsap.to(".menu-header-container", { y: "-100%" });
			gsap.to(".post-title", { y: "0%" });
		},
		onEnterBack: (self) => {
			gsap.to(".menu-header-container", { y: "0%" });
			gsap.to(".post-title", { y: "100%" });
		},
	});

	gsap.to("#progress div", {
		width: "100%",
		ease: "none",
		scrollTrigger: {
			trigger: "body",
			start: "top=+100% bottom",
			// markers: true,
			end: "bottom bottom",
			scrub: 0.1,
		},
	});
}

// ScrollTrigger.matchMedia({
// 	// large
// 	"(min-width: 1024px)": function() {
// 		gsap.to(".pin-media", {
// 			scrollTrigger: {
// 				trigger: ".pin-media",
// 				pin: true,
// 				pinSpacing: false,
// 				start: "top " + $(".pin-media").position().top,
// 				endTrigger: ".post-content",
// 				end:
// 					$(".post-content").height() -
// 					$(".post-tags-height").innerHeight() -
// 					75,
// 			},
// 		});
// 		gsap.to(".post-tags > div", {
// 			scrollTrigger: {
// 				trigger: ".post-tags",
// 				pin: true,
// 				pinSpacing: false,
// 				start: "top " + $(".post-tags-wrap").offset().top,
// 				// endTrigger: ".post-content",
// 				endTrigger: ".post-content",
// 				end:
// 					$(".post-content").height() -
// 					$(".post-tags-height").innerHeight() -
// 					75,
// 				// markers: true,
// 			},
// 		});
// 	},
// });
